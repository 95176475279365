@import url('https://fonts.googleapis.com/css?family=Roboto');
@import url('https://fonts.googleapis.com/css?family=Questrial');

body {
  font-family: 'Roboto';
}

#root {
  width: 100%;
}

.pagination-links .selected {
  background-color: #EB1C22;
  color: #fff;
  border-radius: 50%/50%;
  font-weight: bold;
  cursor: pointer;
}

.pagination-links li {
  list-style-type: none;
  background-color: #D9D9D9;
  color: white;
  font-weight: bold;
  margin: 5px;
  border-radius: 50%/50%;
  min-width: 30px;
  min-height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.pagination-links .previous, .pagination-links .next {
  display: none;
}

#conditions-table th {
  text-align: left;
  background-color: #D9D9D9;
}

#conditions-table tr {
  padding: 5px;
}

#conditions-table tbody tr:nth-child(even) {
  background-color: #D9D9D9;
}

.about-us-ul {
  margin-left: -1.5em;
}

.about-us-ul li {
  list-style-position: outside;
  padding-left: -0.5em;
  padding-bottom: 20px;
}

.menuLink {
  width: 94.5%;
  padding: 5px 5px 10px 10px;

  align-items: center;
}

.menuLink:first-child {
  border-radius: 5px 5px 0px 0px;
}

.menuLink:last-child {
  border-radius: 0px 0px 5px 5px;
}

.menuLink:hover {
  background-color: #EB1C22;
}

.menuLink:hover a, .menuLink:hover span {
  color: white;
}

a.menuLink:hover {
  color: white !important;
}

.view-auction-button a {
  color: white !important;
}

.react-player-wrapper {
  width: 100%;

  @media(max-width: 768px) {
    margin-bottom: 0;
    margin-top: 0;
  }
}

.react-player {
  position: relative;

  @media(max-width: 768px) {
    margin-bottom: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-top: 0;
  }
}