

*,
*::after,
*::before {

}

.scroll-container {
  display: flex !important;
  overflow: hidden;
}

/* .carousel-primary {
  max-width: 100%;
  position: relative;
}

.carousel-secondary {
  max-width: 100%;
  position: relative;
} */

.image {
  cursor: pointer;
}

/* .image:hover {
  border: 3px solid black;
} */

/* .carousel-primary {
  display: flex;
  justify-content: space-around;
  animation: scroll-horizontal 20s linear infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

.carousel-secondary {
  animation: scroll-horizontal 20s linear infinite;
  margin-left: 33.5%;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
} */

.go-back {
  animation-direction: reverse;
  animation-duration: 1000s;
}

/* @keyframes scroll-horizontal {
  0% {
    left: 0%;
  }

  100% {
    left: -133.5%;
  }
} */

@keyframes go-back {

  100% {
    right: 10%;
  }
}

.carousel-primary:hover,
.carousel-primary:hover~.carousel-secondary {
  -webkit-animation-play-state: paused;
  -moz-animation-play-state: paused;
  -o-animation-play-state: paused;
  animation-play-state: paused;
}

.carousel-secondary:hover {
  -webkit-animation-play-state: paused;
  -moz-animation-play-state: paused;
  -o-animation-play-state: paused;
  animation-play-state: paused;
}


.scroll-container:hover>.carousel-primary:not(:hover) {
  -webkit-animation-play-state: paused;
  -moz-animation-play-state: paused;
  -o-animation-play-state: paused;
  animation-play-state: paused;
}