trix-editor {
  padding: 10px !important;
}

.trix-button-row {
  display: flex;
  flex-wrap: wrap !important;
  justify-content: flex-start;
  gap: 5px;
  max-width: 100%;
}

trix-toolbar .trix-button-group button.trix-button--icon-bold::selected {
  background-color: chartreuse;
}

.trix-button--icon-increase-nesting-level,
.trix-button--icon-decrease-nesting-level,
.trix-button--icon-strike,
.trix-button--icon-code { display:none; }

iframe, video {
  max-width: 100%;
  height: auto;
}

.attachment img {
  max-width: 100%;
  height: auto;
}
